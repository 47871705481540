import randomstring from 'randomstring'
import EventEmitter from 'event-emitter-es6'
import _ from 'lodash'
import RequestQueue from './RequestQueue'

/**
 * Сущность запрос, после инстанцирования и навешивания
 * обработчиков запрос пердается на исполнение методом execute.
 * @example
 const req = new Vue.$b24.Request('user.get')
 req.on('progress', console.log)
 req.on('finish', console.log)
 req.execute().then(console.log).catch(console.error)
 */
class Request extends EventEmitter {
  constructor (method, params) {
    super()
    this.elemsPerChunk = 50 // элементов возвращает битрикс за 1 простой запрос
    this.method = method
    this.params = params
    this.id = this._randStr()
    this._data = [] // сюда складываются результирующие данные
    this._errors = [] // ошибки
    this._totalElems = null
    this._loadedElems = 0
    this._totalChunks = null
    this._chunksGenerated = 0
    this._offset = 0
    this._chunks = []
    this._timeStart = 0
    this._promise = null
    this._resolve = null
    this._reject = null
  }

  /**
   * Фабричный метод
   */
  static create (...args) {
    return new Request(...args)
  }

  /**
     * Завершен ли запрос
     * @returns {boolean}
     */
  get finished () {
    return this._loadedElems >= this._totalElems
  }

  /**
   * Время выполнения запроса
   * @returns {number}
   */
  get execTime () {
    return +new Date() - this._timeStart
  }

  /**
   * произвольная строка из букв, длинной 12
   * @private
   */
  _randStr () {
    return randomstring.generate({
      length: 12,
      charset: 'alphabetic'
    })
  }

  /**
   * переносит курсор дальше по выборке на elemsPerChunk элементов
   * @private
   */
  _increaseOffset () {
    this._offset += this.elemsPerChunk
  }

  /**
   * Создает части запроса
   * @returns {boolean}
   * @private
   */
  _generateChunks () {
    if (_.isNull(this._totalElems)) {
      this._createChunk()
      this._increaseOffset()
      this._totalElems = 0
    } else {
      if (this._chunksGenerated >= this._totalChunks) {
        return false
      }
      for (let i = 1; i < this._totalChunks; i++) {
        this._createChunk()
        this._increaseOffset()
      }
    }
  }

  /**
   * Создает часть запроса, вызов метода который будет помещен в batch
   * @returns {{[p: string]: *}}
   * @private
   */
  _createChunk () {
    const chunk = {
      [`cnk_${this.id}_${this._offset}`]: [this.method, _.extend({ start: this._offset }, this.params)]
    }
    this._chunks.push(chunk)
    ++this._chunksGenerated
    return chunk
  }

  /**
     * Обрабатывает выполненную часть(chunk) запроса
     * @param result
     * @param offset
     */
  processChunkResult (result, offset) {
    if (this._totalElems === 0) {
      this._totalElems = result.total() || 0 //  bitrix ticket 2304425
      this._totalChunks = Math.ceil(this._totalElems / this.elemsPerChunk)
      this._data = _.times(this._totalElems, _.constant(null))
    }

    let data = result.data()
    // bitrix ⚣
    !_.isArray(data) && (data = [data])
    _.isEmpty(data) && (data = [])

    const err = result.error()
    err && this._errors.push(err)

    this._data.splice(offset, this.elemsPerChunk, ...data) // lodash has no mutable fns
    this._loadedElems += _.size(data)

    this._emitProgress()
    this._controlFinish()
  }

  /**
     * Следит и выполнятет действия когда запрос выполнен
     * @private
     */
  _controlFinish () {
    if (this.finished) {
      this.emit('finish', { time: this.execTime / 1e3 })
      this._resolve(this._data)
    }
  }

  /**
     * Создает событие progress
     * @private
     */
  _emitProgress () {
    let percent = 0
    this._totalElems && (percent = _.round((this._loadedElems * 100) / this._totalElems))
    this.emit('progress', {
      total: this._totalElems,
      abs: this._loadedElems,
      percent
    })
  }

  /**
     * Вернет count частей запроса
     * @param count
     */
  getChunks (count) {
    this._generateChunks()
    !count && (count = _.size(this._chunks))
    const res = _.slice(this._chunks, 0, count)
    this._chunks = _.slice(this._chunks, count)
    return res
  }

  /**
     * Выполнить запрос, резолвится когда все чанки загружены
     */
  execute () {
    const self = this
    this._promise = new Promise((resolve, reject) => {
      self._resolve = resolve
      self._reject = reject
    })
    this._timeStart = +new Date()
    RequestQueue.addRequest(this)
    return this._promise
  }

  /**
   * Массив ошибок запроса
   */
  get errors() {
    return _.map(this._errors, err => {
      let error = new Error(`Unknown error`)

      if (err.ex) {
        //битриксовая ошибка типа `ajaxError`
        error = new Error(`${err.status} ${err.ex.error} ${err.ex.error_description}`)
      }

      if (err.message) {
        //наследник стаднтарной ошибки
        error = err
      }

      return error
    })
  }

  /**
   * Данные запроса
   */
  get data() {
    return this._data || [];
  }
}

export default Request
