import BX24 from './bitrix'
import _ from 'lodash'
import Request from './Request'

/**
 * Очередь запросов, сюда передаются сконструированные запросы, бьются на чанки, исполняются
 */
class RequestQueue {
  constructor () {
    if (this.constructor.instance) {
      return this.constructor.instance
    }

    this.chunkPerBatch = 50
    this.chunks = []
    this._requests = []
    this._exec = null
    this.start()
  }

  /**
     * Добавляет новый запрос в очередь
     * @param req
     */
  addRequest (req = {}) {
    if (!(req instanceof Request)) {
      return
    }
    this._requests.push(req)
    req.on('finish', this._removeRequest.bind(this, req.id))
  }

  /**
     * Собирает чанки с активных запросов
     * @returns {Array}
     * @private
     */
  _collectChunks () {
    const res = []
    const self = this

    _.each(this._requests, req => {
      self.chunks = _.concat(self.chunks, req.getChunks())
    })

    return res
  }

  /**
   * Выбирает из массива чанков те которые должны быть выполнены
   * @private
   */
  _getChunksForBatch () {
    const res = {}
    // todo тут должна быть логика определения приоритетов запросов
    this.chunks = _.shuffle(this.chunks) // пока все запросы считаем эквивалентого приоритета
    const slice = _.slice(this.chunks, 0, this.chunkPerBatch)
    this.chunks = _.slice(this.chunks, this.chunkPerBatch)

    _.each(slice, chunk => {
      res[_.keys(chunk)[0]] = _.values(chunk)[0]
    })

    return res
  }

  /**
   * Вызывается каждые 50мс, собирает чанки с запросов, выполняет batch если необходимо
   * @private
   */
  _process () {
    this._collectChunks()
    const chunks = this._getChunksForBatch() // get 50 chunks from chunk queue
    if (_.isEmpty(chunks)) {
      return
    }
    this._batch(chunks).then(this._processBatchResult.bind(this)).catch(console.error)
  }

  /**
   * Парсит название чанка cnk_qweqweqwe_30 -> {reqId: 'qweqweqwe', offset: 30}
   * @param chunkId
   * @returns {{reqId: null, offset: null}}
   * @private
   */
  _parseRequestId (chunkId = '') {
    const res = {
      reqId: null,
      offset: null
    }

    const exploded = _.split(chunkId, '_')
    res.reqId = exploded[1]
    res.offset = exploded[2]

    return res
  }

  /**
   * Обрабатывает возвращенный рестом результат batch запроса
   * @param result
   * @private
   */
  _processBatchResult (result = {}) {
    const self = this
    _.each(result, (chunkRes, chunkId) => {
      const parsedChunkId = self._parseRequestId(chunkId)
      const request = _.first(_.filter(this._requests, ['id', parsedChunkId.reqId]))
      request && request.processChunkResult(chunkRes, parsedChunkId.offset)
    })
  }

  /**
     * Удаляет запрос из очереди
     * @param reqId
     * @private
     */
  _removeRequest (reqId = '') {
    reqId && _.remove(this._requests, [ 'id', reqId ])
  }

  /**
     * Выполнят batch запрос к B24 rest
     * @returns {Promise<any>}
     * @private
     */
  _batch () {
    // npm promisify not working here
    return new Promise(resolve => {
      BX24.callBatch(...arguments, resolve)
    })
  }

  /**
   * Старт механизма выполнения запросов очереди
   */
  start () {
    this._exec = setInterval(this._process.bind(this), 50)
  }

  /**
   * Остановка механизма выполнения запросов очереди
   */
  stop () {
    this._exec && clearInterval(this._exec)
  }
}

RequestQueue.instance = new RequestQueue()

export default RequestQueue.instance
